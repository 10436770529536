import React from "react";
import supportus from './images/supportus.png'

function Header() {
  
  return (
    <div className="header">
      <div className="mainTitle"><h1>節目重溫</h1></div>
      <div className="rightPanel">
        <div className="RSS"><h2><a href="/api/rss" target="_blank" rel="noopener noreferrer" alt="RSS">RSS 訂閱</a></h2></div>
        <div className="supportus"><h3><a href="https://buymeacoffee.com/likeandshare" target="_blank" rel="noopener noreferrer" alt="support us"><img src={supportus} alt="support us img"/>支持網站營運</a></h3></div>
      </div>    
    </div>
  );
}

export default React.memo(Header);
