import { useEffect, useState } from 'react'
import axios from 'axios'

export default function LoadSections(section_date) {
const [loading, setLoading] = useState(true)
const [sections, setSections] = useState([])
const [next_section_date, setNext_section_date] = useState()
    useEffect(() => {
        setLoading(true)
        let cancel
        axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL,
        params: { [(section_date.length === 7 ? 'section_date' : 'next_section_date')]: section_date },
        cancelToken: new axios.CancelToken(c => cancel = c)
        }).then(res => {    
              
            setSections(prevSections => {
                return [...new Set([...prevSections, ...res.data.response.program_archives.map(s => s.section_date)])]
            })      
        setNext_section_date(res.data.response.next_section_date)
        setLoading(false)
        }).catch(e => {
        if (axios.isCancel(e)) return

        })
        return () => cancel()
    }, [section_date])

return { loading,sections, next_section_date }
}