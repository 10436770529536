import React, { useEffect, useState, forwardRef, useContext } from "react";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { PlayerStateContext } from "./Context";
import axios from 'axios'
function Episode({ date, initiator }, ref) {
  const { playerOption, dispatch } = useContext(PlayerStateContext);

  const [episode, setEpisode] = useState({
    episodeDate: 'loading...',
    title1: 'loading...',
    title2: null,
    url: null
  });
  useEffect(() => {
    axios.get(`/api/getepisode/${date}`).then(resp => {
      setEpisode(resp.data.data)
    }).catch((err) => {
      setEpisode({ error: err.message })
    })
  }, [date]);

  const play = () => {
    if (playerOption.audioLists == null) { return playOne(); }
    const index = getIndexinList()
    if (index >= 0) {
      dispatch({ type: 'PLAY_INDEX', payload: index });
    } else {
      dispatch({ type: 'ADD_TO_PLAYLIST_AND_PLAY', payload: getItem() });
    }
  };
  const addtoPlaylist = () => {
    if (playerOption.audioLists == null) { return playOne(); }
    if (getIndexinList() >= 0) { return }
    dispatch({ type: 'ADD_TO_PLAYLIST', payload: getItem() });
  };
  const playOne = () => {
    dispatch({ type: 'PLAY_ONE', payload: getItem() });
  };

  const getIndexinList = () => {
    return playerOption.audioLists.findIndex(item => item.name === episode.episodeDate);
  };

  const getItem = () => {
    return {
      name: episode.episodeDate,
      singer: episode.title1,
      musicSrc: episode.url
    }
  }

  if (!episode.error) {
    return (
      <div className="episode" ref={ref}>
        <div className="title">
          <h1 className="title__date">{episode.episodeDate}</h1>
          <h2 className="title__title">{episode.title1}</h2>
          {(episode.title1 != null && episode.title2 != null ? 
              (episode.title1.replace(/\s/g,'') !== episode.title2.replace(/\s/g,'') ? (<h2 className="title__title">{episode.title2}</h2>) : '') 
            : false)
          }
        </div>
        <div className="control">
          <div className="button w3-btn w3-white w3-border w3-round w3-hover-sand" onClick={() => { initiator.current = 'SELF'; play() }}>
            <PlayCircleFilledIcon className="icon" />
            <span>播放</span>
          </div>
          <div className="button w3-btn w3-white w3-border w3-round w3-hover-sand" onClick={() => { initiator.current = 'SELF'; addtoPlaylist() }}>
            <PlaylistAddIcon className="icon" />
            <span>加到播放清單</span>
          </div>
        </div>
      </div>
    );
  } else {
    return (null);
  }

}

export default forwardRef(Episode);