import React, { useState, useContext, useCallback } from "react";
import ReactJkMusicPlayer from 'react-jinke-music-player'
import { PlayerStateContext } from "./Context";
let audio;

const zh_HK = {
  playModeText: {
    order: '順序播放',
    orderLoop: '列表循環',
    singleLoop: '單曲循環',
    shufflePlay: '隨機播放',
  },
  openText: '打開',
  closeText: '關閉',
  emptyText: '播放列表為空',
  clickToPlayText: '播放',
  clickToPauseText: '暫停',
  nextTrackText: '下一首',
  previousTrackText: '上一首',
  reloadText: '重新播放',
  volumeText: '音量',
  playListsText: '播放列表',
  toggleLyricText: '顯示/隱藏 歌詞',
  toggleMiniModeText: '切換至迷你模式',
  destroyText: '移除播放器',
  downloadText: '下載',
  lightThemeText: '明亮',
  darkThemeText: '暗黑',
  switchThemeText: '暗黑/明亮 主題',
  removeAudioListsText: '清除播放列表',
  clickToDeleteText: (name) => `點擊刪除 ${name}`,
  emptyLyricText: '暫無歌詞',
  loadingText: '載入中',
  speedSwitchText: '倍速',
}



function Player({ initiator }) {
  const { playerOption, dispatch } = useContext(PlayerStateContext);
  const [speed, setSpeed] = useState('1.5X');
  const handleAudioListChange = useCallback((_currentPlayId, _audioLists, _audioInfo) => {
    //console.log('callback executed')
    if (initiator.current === 'SELF' || initiator.current === 'PLAYER') return;
    initiator.current = 'PLAYER';    
    if (_audioLists.length === 0) {
      dispatch({ type: 'CLEAR_PLAYLIST' });
    } else {
      dispatch({ type: 'UPDATE_PLAYLIST', payload: _audioLists });
      
    }
  },[dispatch, initiator]);

  const handleAudioPlayTrackChange = ((_currentPlayId, _audioLists, _audioInfo) => {
    setSpeed('1.5X');
  });

  return (
    
    <div className="player">           
      <ReactJkMusicPlayer  {...playerOption} locale={zh_HK}
        onAudioListsChange={handleAudioListChange}
        onAudioPlayTrackChange={handleAudioPlayTrackChange}
        getAudioInstance = {(instance) => {audio = instance}}
        extendsContent = {[
          <span class="group speed-switch" title={zh_HK.speedSwitchText} >
            <span onClick={()=> {
              if(audio.playbackRate === 1){
                audio.playbackRate = 1.5;
                setSpeed('2X');
              }else if(audio.playbackRate === 1.5){
                audio.playbackRate = 2;
                setSpeed('1X');
              }else if(audio.playbackRate === 2){
                audio.playbackRate = 1;
                setSpeed('1.5X');
              }
              }} >
              {speed}
            </span>
          </span>
        ]}
      />
     
    </div>
  );
}

export default Player;