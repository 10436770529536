import SearchBar from "material-ui-search-bar";
import React from "react";
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';


const refreshAuthLogic = failedRequest =>
    axios({
        method: 'post',
        url: '/api/getaccess',
        data: {
            LOGIN_SECRET: process.env.REACT_APP_LOGIN_SECRET
        }
    }).then(tokenRefreshResponse => {
        sessionStorage.setItem('ACCESS_TOKEN', tokenRefreshResponse.data.ACCESS_TOKEN);
        failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.ACCESS_TOKEN;
        return Promise.resolve();
    });
createAuthRefreshInterceptor(axios, refreshAuthLogic, { statusCodes: [401, 403] });


function Search({ setSearchResult }) {
    const search = (value) => {
        axios.get(`/api/getepisode/search/${value}`, { headers: {"Authorization" : `Bearer ${sessionStorage['ACCESS_TOKEN']}`} }).then(resp => {
            const output = [];
            resp.data.data.forEach(item => output.push(item.episodeDate));
            //console.log(output);
            setSearchResult(output)
        }).catch((err) => {
            setSearchResult([]);
            return 'Search error...';
        })
    }


    return (
        <div className="searchbar">
            <SearchBar
                placeholder="搜尋..."
                cancelOnEscape={true}
                onCancelSearch={() => setSearchResult()}
                onChange={(value) => { (value !== '') ? search(value) : setSearchResult()  }}
            />
        </div>
    );
}

export default React.memo(Search);