import React, { useState, useRef, useCallback, useReducer, useEffect } from 'react'
import ReactDOM from 'react-dom';
import LoadSections from './Summit'
import { PlayerStateContext } from "./Context";
import moment from 'moment';
import './styles.scss';
import 'react-jinke-music-player/assets/index.css'
import * as serviceWorker from './serviceWorker';

import Episode from "./Episode";
import Header from "./Header";
import Search from "./Search";
import Player from "./Player";

const playerInitialState = {
  audioLists: [],
  autoPlay: true,
  preload: true,
  mode: 'full',
  quietUpdate: true,
  mobileMediaQuery: null,
  clearPriorAudioLists: false,
  showMiniModeCover: false,
  showDownload: false,
  showPlayMode: false,
  showMediaSession: true,
  showThemeSwitch: false,
  autoHiddenCover: true,
};

function playerReducer(state = playerInitialState, action) {
  switch (action.type) {
    case 'PLAY_ONE':
      return {
        ...state,
        audioLists: [action.payload],
        playIndex: undefined,
      }
    case 'PLAY_INDEX':
      return {
        ...state,
        playIndex: action.payload,
      }
    case 'ADD_TO_PLAYLIST':
      return {
        ...state,
        audioLists: [...state.audioLists, action.payload],
        playIndex: undefined,
      }
    case 'ADD_TO_PLAYLIST_AND_PLAY':
      return {
        ...state,
        audioLists: [...state.audioLists, action.payload],
        playIndex: state.audioLists.length,
      }
    case 'CLEAR_PLAYLIST':
      return {
        ...playerInitialState,
        audioLists: [],
        playIndex: undefined,
        clearPriorAudioLists: true,
      }
    case 'UPDATE_PLAYLIST':
      return {
        ...state,
        audioLists: action.payload,
        playIndex: undefined,
        clearPriorAudioLists: false,
      }
    default:
      return state
  }
}

function App() {

  const [stop, setStop] = useState(false);
  const [searchResult, setSearchResult] = useState();
  const [section_date, setSection_date] = useState(moment().format("YYYY-MM"));
  const { loading, sections, next_section_date } = LoadSections(section_date);
  const [playerOption, dispatch] = useReducer(playerReducer, playerInitialState);
  const initiator = useRef('');
  const observer = useRef();
  let resultSet = null;

  const lastElementRef = useCallback(node => {
    if (loading || stop) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setSection_date(next_section_date)
        if (new RegExp('^2021-03.*').test(next_section_date)) {
          setStop(true)
        }
      }
    })
    if (node) observer.current.observe(node)
  }, [stop, loading, next_section_date]);

  useEffect(() => {
    //console.log(playerOption.audioLists)
    //console.log('initiator: ', initiator.current)
    initiator.current = null;
  }, [playerOption]);


  (searchResult) ? resultSet = searchResult : resultSet = sections;
  return (
    <div className="App">
      <Header />
      <Search setSearchResult={setSearchResult}/>
      <PlayerStateContext.Provider value={{ playerOption, dispatch }}>
        {
          resultSet.map((section, index) => {
            if (resultSet.length === index + 1 && !searchResult) {
              return <Episode ref={lastElementRef} key={section} date={section} initiator={initiator} />
            } else {
              return <Episode key={section} date={section} initiator={initiator} />
            }
          })
        }
        <Player initiator={initiator} />
      </PlayerStateContext.Provider>
    </div>
  );
}

ReactDOM.render(
  <App />,
  document.getElementById("root")
);

serviceWorker.unregister();
